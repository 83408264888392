var render = function () {
  var _vm$user, _vm$user2, _vm$user3, _vm$user3$roles$data$, _this$user;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "navbar-container d-flex content align-items-center"
  }, [_c('ul', {
    staticClass: "nav navbar-nav d-xl-none"
  }, [_c('li', {
    staticClass: "nav-item"
  }, [_c('b-link', {
    staticClass: "nav-link",
    on: {
      "click": _vm.toggleVerticalMenuActive
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "MenuIcon",
      "size": "21"
    }
  })], 1)], 1)]), _c('div', {
    staticClass: "bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex"
  }, [_c('dark-Toggler', {
    staticClass: "d-none d-lg-block"
  })], 1), !((_vm$user = _vm.user) !== null && _vm$user !== void 0 && _vm$user.super_admin) && (_vm$user2 = _vm.user) !== null && _vm$user2 !== void 0 && _vm$user2.admin ? _c('div', {
    staticClass: "btn btn-info mr-1",
    on: {
      "click": _vm.startTour
    }
  }, [_vm._v("Instruction")]) : _vm._e(), ((_vm$user3 = _vm.user) === null || _vm$user3 === void 0 ? void 0 : (_vm$user3$roles$data$ = _vm$user3.roles.data[0]) === null || _vm$user3$roles$data$ === void 0 ? void 0 : _vm$user3$roles$data$.admin) === 1 ? _c('XdeskView', {
    attrs: {
      "details": _vm.xdeskUser,
      "is-admin": false,
      "secret-key": "secret-2h82qz59nfl7kgvkurhkui824",
      "set-loader": _vm.setLoading,
      "is-loading": _vm.isLoading
    }
  }, [_c('div', {
    staticClass: "btn btn-success"
  }, [_vm._v(" " + _vm._s(_vm.isLoading ? "Please Wait" : "Help") + " ")])]) : _vm._e(), _c('b-navbar-nav', {
    staticClass: "nav align-items-center ml-auto"
  }, [((_this$user = this.user) === null || _this$user === void 0 ? void 0 : _this$user.super_admin) !== 1 ? [_c('notification-dropdown')] : _vm._e(), _vm.$permissionAbility(_vm.ATTENDANCE_CHECK_IN_OUT, _vm.permissions) ? void 0 : _vm._e(), _c('b-nav-item-dropdown', {
    staticClass: "dropdown-user",
    attrs: {
      "right": "",
      "toggle-class": "d-flex align-items-center dropdown-user-link"
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function fn() {
        var _vm$user4, _vm$user5, _vm$user5$designation, _vm$user5$designation2, _vm$user6, _vm$user6$designation, _vm$user6$designation2, _vm$user7, _vm$user7$designation, _vm$user7$designation2, _vm$user8, _vm$user9;
        return [_c('div', {
          staticClass: "d-sm-flex d-none user-nav"
        }, [_c('p', {
          staticClass: "user-name font-weight-bolder mb-0"
        }, [_vm._v(" " + _vm._s((_vm$user4 = _vm.user) === null || _vm$user4 === void 0 ? void 0 : _vm$user4.name) + " ")]), _c('span', {
          staticClass: "user-status"
        }, [((_vm$user5 = _vm.user) === null || _vm$user5 === void 0 ? void 0 : (_vm$user5$designation = _vm$user5.designation) === null || _vm$user5$designation === void 0 ? void 0 : (_vm$user5$designation2 = _vm$user5$designation.data) === null || _vm$user5$designation2 === void 0 ? void 0 : _vm$user5$designation2.name.length) >= 10 ? [_vm._v(" " + _vm._s((_vm$user6 = _vm.user) === null || _vm$user6 === void 0 ? void 0 : (_vm$user6$designation = _vm$user6.designation) === null || _vm$user6$designation === void 0 ? void 0 : (_vm$user6$designation2 = _vm$user6$designation.data) === null || _vm$user6$designation2 === void 0 ? void 0 : _vm$user6$designation2.name.substr(0, 10)) + "... ")] : [_vm._v(" " + _vm._s((_vm$user7 = _vm.user) === null || _vm$user7 === void 0 ? void 0 : (_vm$user7$designation = _vm$user7.designation) === null || _vm$user7$designation === void 0 ? void 0 : (_vm$user7$designation2 = _vm$user7$designation.data) === null || _vm$user7$designation2 === void 0 ? void 0 : _vm$user7$designation2.name) + " ")]], 2)]), _c('b-avatar', {
          staticClass: "badge-minimal",
          attrs: {
            "size": "40",
            "variant": "light-primary",
            "badge": "",
            "src": ((_vm$user8 = _vm.user) === null || _vm$user8 === void 0 ? void 0 : _vm$user8.avatar) === '' ? '/avatar.svg' : (_vm$user9 = _vm.user) === null || _vm$user9 === void 0 ? void 0 : _vm$user9.avatar,
            "badge-variant": "success"
          }
        })];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', {
    attrs: {
      "link-class": "d-flex align-items-center"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return function () {
          return _vm.$route.name != 'user-profile' ? _vm.$router.push({
            name: 'user-profile',
            params: {
              id: _vm.user.id
            }
          }) : null;
        }($event);
      }
    }
  }, [_c('feather-icon', {
    staticClass: "mr-50",
    attrs: {
      "size": "16",
      "icon": "UserIcon"
    }
  }), _c('span', [_vm._v("Profile")])], 1), _c('b-dropdown-divider'), _c('b-dropdown-item', {
    attrs: {
      "link-class": "d-flex align-items-center"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.onLogout($event);
      }
    }
  }, [_c('feather-icon', {
    staticClass: "mr-50",
    attrs: {
      "size": "16",
      "icon": "LogOutIcon"
    }
  }), _c('span', [_vm._v("Logout")])], 1)], 1)], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }